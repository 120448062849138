<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import Loading from './components/general/Loading.vue'
import staticWorkspaces from '@/support/static-workspaces/workspaces.json'
import ModalShowMessage from '@/components/general/ModalShowMessage'
import ModalChangeWorkspace from '@/components/home/ModalChangeWorkspace.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Loading,
    ModalShowMessage,
    HeaderMenu: () => import('@/components/general/HeaderMenu'),
    Snackbar: () => import('@/components/general/Snackbar'),
    Feedback: () => import('@/components/general/Feedback'),
    HelpCenterBtn: () => import('@/components/general/HelpCenterBtn'),
    AppBanner: () => import('@/components/general/AppBanner'),
    ModalChangeWorkspace
  },
  data () {
    return {
      userWorkspace: null,
      applicationLoaded: false,
      loading: false
    }
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (this.$options.parent.$options.syncData.user && this.$options.parent.$options.syncData.user.isSupport && !to.name.includes('business.admin.center')) {
          this.$router.push({ name: 'business.admin.center.index', params: { companyDomain: this.$route.params.companyDomain } })
        }
        if (this.selectedWorkspace !== undefined) {
          if (this.selectedWorkspace.type === 'business') {
            const company = this.$store.getters.getUser.companies.filter(company => company.subdomain === this.selectedWorkspace.subdomain)[0]
            if (this.selectedWorkspace.userTosAcceptedAt === null && company.tosAcceptedAt === undefined) {
              if (from.name !== 'business.tos') {
                if (Object.prototype.hasOwnProperty.call(from, 'redirectedFrom')) {
                  this.$router.push({ name: 'business.tos', query: { path: from.redirectedFrom } }).catch(() => { })
                } else {
                  this.$router.push({ name: 'business.tos', query: { ...this.$route.query, path: from.path } }).catch(() => { })
                }
              }
            }
          }
        }

        if (this.$store.getters.getWizard.active) {
          if (JSON.stringify(this.$route.query) !== JSON.stringify(this.$store.getters.getWizard.theme)) this.$router.push({ name: 'newaccount.home.welcome', query: { ...this.$route.query, ...this.$store.getters.getWizard.theme } })
          const wizardSteps = ['newaccount.home.welcome', 'newaccount.home.firstAccess.categories', 'newaccount.home.firstAccess.skills', 'newaccount.home.firstAccess.profile']
          if (!wizardSteps.includes(to.name) && !this.$store.getters.getWizard.completed) {
            this.$router.push({ name: 'newaccount.home.welcome', query: { ...this.$route.query, ...this.$store.getters.getWizard.theme } })
          }
        }

        if (to.name === 'workspace.selection.index') {
          if (this.userWorkspaces.length === 1) {
            this.$router.push({ name: 'individual.home.user', params: { workspace: this.userWorkspaces[0] } })
          } else if (this.userWorkspaces.filter(c => c.type === 'business').length === 1 && !this.userWorkspaces.filter(c => c.type === 'aggregator').length) {
            this.$router.push({ name: this.userWorkspaces[1].type + '.home.user', params: { companyDomain: this.userWorkspaces[1].subdomain } })
          } else if (this.userWorkspaces.filter(c => c.type === 'aggregator').length && this.userWorkspaces.length === 2) {
            if (this.lastSelection && this.lastSelection === 'individual') {
              this.$router.push({ name: 'individual.home.user', params: { workspace: this.userWorkspaces[0] } })
            } else {
              const workspace = this.userWorkspaces.find(workspace => workspace.type === 'aggregator')
              this.$router.push({ name: workspace.type + '.home.user', params: { companyDomain: workspace.subdomain } })
            }
          }
        }

        if (from.name === 'auth.register' && to.name === 'auth.login') {
          location.reload()
        }
        from && this.$store.commit('setLastRoute', { name: from.name, params: from.params })
        // this.defineLanguage(to)
        if (this.routerMeta.public) this.applicationLoaded = true

        if (this.$route.name.includes('admin.center') && !this.userCanManage(this.selectedWorkspace.id)) {
          if (!Object.keys(this.selectedWorkspace).length) this.$router.push({ path: '/' })
          else this.$router.push({ name: this.selectedWorkspace.type + '.home.user' })
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('user.not.allowed:to.access')
          })
        }
      }
    },
    '$store.getters.getUser' (newUser, oldUser) {
      if ((newUser && newUser.userId) !== (oldUser && oldUser.userId)) {
        this.validateAccount()
      }
    },
    '$store.getters.getLanguage': {
      immediate: true,
      handler () {
        // const savedLanguage = JSON.parse(localStorage.getItem('vuex'))?.language
        // const language = !savedLanguage ? navigator.language : savedLanguage
      }
    }
  },
  computed: {
    userToken () {
      return this.$store.getters.getUserToken
    },
    getFeedbackMsg () {
      return this.$store.getters.getFeedbackMsg
    },
    getHelpMsg () {
      return this.$store.getters.getHelpMsg
    },
    isAllowedLoading () {
      return !this.$route.meta.preventRoute
    },
    routerMeta () {
      return this.$route.meta
    },
    isWorkspaceSetup () {
      return this.$route && this.$route.name && this.$route.name.includes('workspace.setup')
    },
    languageIsLoaded () {
      return this.$store.getters.getLanguageIsLoaded
    },
    getShowSpinner () {
      return this.$store.getters.getShowSpinner
    },
    userWorkspaces () {
      return this.$store.getters.getUserWorkspaces
    },
    lastSelection () {
      return this.$store.getters.getLastWorkspaceSelected
    },
    getInviteDetails () {
      return this.$store.getters.getInviteDetails
    },
    verifyTos () {
      return this.$route && this.$route.name && !this.$route.name.includes('tos')
    }
  },
  methods: {
    ...mapActions(['setLanguage']),
    language (language) {
      switch (language) {
        case 'pt':
        case 'pt-br':
        case 'pt-BR':
        case 'PT-BR':
          return 'pt-BR'
        case 'EN':
        case 'en':
        case 'en-US':
          return 'en'
      }
    },
    validateAccount () {
      if (this.isUserDeleted) {
        this.$router.push({ name: 'reactive.account' })
      } else if (!this.objIsEmpty(this.$store.getters.getEnterpriseWorkspace) && !this.$store.getters.getEnterpriseWorkspace.isCompleted) {
        if (this.$route.name !== 'workspace.setup') {
          this.$router.push({ name: 'workspace.setup' })
        }
      }
    },
    // defineLanguage (language) {
    //   this.$store.getters.getUserWorkspaces.map(workspace => {
    //     if (workspace.subdomain === this.$route.params.companyDomain) {
    //       if (this.$route.name === 'business.home.user') {
    //         this.setLanguage(this.language(language || workspace.language))
    //       }
    //     }
    //   })

    //   if (this.$route.name === 'individual.home.user' || this.$route.name === 'aggregator.home.user' || this.$route.name === 'workspace.selection.index') {
    //     if (language) {
    //       this.setLanguage(this.language(language))
    //     } else {
    //       let userHire = null

    //       this.$store.dispatch('attemptGetHireUserInfo')
    //         .then(({ data }) => {
    //           userHire = this.language(data.user.language)
    //           this.setLanguage(userHire)
    //         })
    //     }
    //   }
    // },
    setupZendesk () {
      // eslint-disable-next-line no-undef
      zE('webWidget', 'setLocale', this.getUserInfo.locale)
      // eslint-disable-next-line no-undef
      zE('webWidget', 'prefill', {
        name: {
          value: `${this.getUserInfo.name} ${this.getUserInfo.lastName || this.getUserInfo.family_name}`,
          readOnly: true
        },
        email: {
          value: this.getUserInfo.email,
          readOnly: true
        }
      })
      this.setupZendeskCustomFields()
    },
    setup () {
      const userData = this.$options.parent.$options.syncData.user
      if (userData) {
        this.setLanguage(userData.language)
        this.$store.commit('setLanguageIsLoaded', true)
        this.$store.dispatch('attemptAutomaticLogin', this.$options.parent.$options.syncData)
        !userData.isSupport && this.$store.dispatch('attemptUpdateUserWorkspaces', this.$options.parent.$options.syncData.workspaces)

        this.$store.getters.getUserWorkspaces.map(workspace => {
          if (this.$route.name && this.$route.name.includes(workspace.type)) {
            if (workspace.type === 'individual' || (this.$route.params.companyDomain && this.$route.params.companyDomain.toLowerCase() === workspace.subdomain.replace(/\s+/g, '').toLowerCase())) {
              this.$store.commit('setSelectedWorkspace', workspace)
            }
          }
        })

        this.applicationLoaded = true
      }
    },
    handleSSO (data) {
      if (data.token) {
        this.SSORedirect()
      }
    },
    closeModal () {
      this.$store.commit('setInviteDetails', { showMessageInvite: false })
    }
  },
  created () {
    this.$store.commit('updateWantedWorkspaceChange', null)
    if (this.$route.query.lang) {
      this.setLanguage(this.$route.query.lang)
    }

    if (this.$options.parent.$options.syncData?.user?.isSupport) {
      const splitPath = window.location.pathname.split('/').filter(i => !!i)
      this.$router.push({ name: 'business.admin.center.index', params: { companyDomain: splitPath[1] } })
    }

    const token = this.$options.parent.$options.syncData.token
    if (token && this.$route.params.companyDomain && this.$route.name.includes('aggregator')) {
      const availableAggregators = (localStorage.getItem('availableAggregators') && JSON.parse(localStorage.getItem('availableAggregators'))) || []
      if (availableAggregators.findIndex(i => i.subdomain === this.$route.params.companyDomain) === -1) {
        const newWorkspace = staticWorkspaces.filter(workspace => {
          return workspace.subdomain === this.$route.params.companyDomain
        })[0]
        availableAggregators.push(newWorkspace)

        localStorage.setItem('availableAggregators', JSON.stringify(availableAggregators))
      }
    }
    this.setup()

    const plooralGlobal = JSON.parse(localStorage.getItem('plooral-global'))

    plooralGlobal && plooralGlobal.visitedWorkspaceTypes && Object.keys(plooralGlobal.visitedWorkspaceTypes).forEach(type => {
      plooralGlobal.visitedWorkspaceTypes[type] === true && this.$store.commit('setShownWorkscapeWelcome', type)
    })
  }
})
</script>
<template>
  <v-app id="app"
    :class="{ 'mobile': isMobile && !isWorkspaceSetup, 'authenticated': userToken || $route.meta.registerRoute, 'authentication-mobile': isMobile && $route && $route.name && $route.name.includes('auth'), 'auth-screen': $route && $route.name && $route.name.includes('auth') }">
    <div v-if="loading">
      <Loading />
    </div>
    <div v-else>
      <v-main v-if="(applicationLoaded || $route.name === 'auth.login')">
        <modal-show-message v-if="getInviteDetails.showMessageInvite" :title="$t(getInviteDetails.title)" :text="$t(getInviteDetails.text,
        {
          name: getInviteDetails.name,
          companyName: getInviteDetails.companyName,
          inviteEmail: getInviteDetails.inviteEmail,
          userEmail: getInviteDetails.userEmail,
          link: 'https://plooral.zendesk.com/hc/pt-br'
        })" @close="closeModal">
        </modal-show-message>
        <feedback v-if="getHelpMsg" :msg="getHelpMsg" />
        <snackbar v-if="getFeedbackMsg" :values="getFeedbackMsg" />
        <div v-if="verifyTos">
          <header-menu v-if="isAllowedLoading && !routerMeta.hideMenu" />
        </div>
        <router-view />
      </v-main>
    </div>
    <help-center-btn v-if="$route && $route.name && $route.name.includes('auth')"></help-center-btn>
    <app-banner v-if="$route && $route.name && $route.name.includes('candidature')"></app-banner>
    <modal-change-workspace :key="selectedWorkspace && selectedWorkspace.subdomain"></modal-change-workspace>
  </v-app>
</template>
<style lang="scss">
html {
  background: #F8F8F8;
}

#app {
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  background-color: #F8F8F8;
}

@media only screen and (max-width: 768px) {
  #app {
    padding-bottom: 70px;
  }

  div>#launcher {
    margin-bottom: 100px !important;
  }

  #app.authentication-mobile {
    padding: 0;
  }
}
</style>
