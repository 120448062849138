import { http, hireHttp } from '@/support/axios'
import { parseToFormDataWithArray, createQuery } from '@/support/payloadParser'

export default {
  state: {
    contentTypes: [],
    userContents: [],
    userContentsInProgress: {
      in_progress: [],
      conclusion: []
    }
  },
  getters: {
    getContentTypes: state => state.contentTypes,
    getUserContents: state => state.userContents,
    getUserInProgressContents: state => state.userContentsInProgress.in_progress,
    getUserConcludedContents: state => state.userContentsInProgress.conclusion
  },
  mutations: {
    updateTypes (state, { data }) {
      state.contentTypes = data
    },
    updateUserContents (state, value) {
      state.userContents = value
    },
    updateUserContentById (state, value) {
      const userContents = [...state.userContents]
      const contentIndex = userContents.findIndex(content => content.id === value.id)
      userContents[contentIndex] = value
      state.userContents = userContents
    },
    updateUserContentsInProgress (state, { status, data }) {
      state.userContentsInProgress[status] = data
    }
  },
  actions: {
    attemptUpdateContentItem ({ commit }, content) {
      commit('updateUserContentById', content)
    },
    attemptGetContentTypes ({ commit }) {
      return http.get('v1/contents/types').then(({ data }) => {
        commit('updateTypes', data)
        return data
      }).catch(() => {
        return { error: true }
      })
    },
    attemptSaveContent (_, contentData) {
      const parsedFormData = parseToFormDataWithArray(contentData)
      if (contentData.coverImage && typeof contentData.coverImage === 'object') {
        parsedFormData.append('coverImage', contentData.coverImage, contentData.coverImage.name)
      }
      return http.post('v1/contents', parsedFormData)
    },
    attemptUpdateContent (_, { contentData, contentId }) {
      const parsedFormData = parseToFormDataWithArray(contentData)
      if (contentData.coverImage && typeof contentData.coverImage === 'object') {
        parsedFormData.append('coverImage', contentData.coverImage, contentData.coverImage.name)
      }
      return http.post(`v1/contents/${contentId}`, parsedFormData)
    },
    attemptGetMyselfContents ({ commit }, filter = null) {
      const query = createQuery({ filter, order: 'DESC' })
      return http.get(`v1/myself/contents?${query}`).then(({ data }) => {
        commit('updateUserContents', data)
        return data
      })
    },
    attemptGetMyselfContentsIndividual ({ commit }, { userId, filter }) {
      if (!userId) return
      const query = createQuery({ filter, order: 'DESC' })
      const url = `v1/contents/user/${userId}?${query}`
      return http.get(url).then(result => {
        return result
      }).catch(() => {
        return { error: true }
      })
    },
    attemptGetFilteredUserContents ({ commit }, { userId, filter }) {
      if (!userId) return { error: true }
      const query = createQuery({ filter, order: 'DESC' })
      const url = `v1/contents/user/${userId}?${query}`
      return http.get(url).then(result => {
        commit('updateUserContentsInProgress', { status: filter.status, data: result.data })
        return result
      }).catch(() => {
        return { error: true }
      })
    },
    attemptSaveAccess (_, contentId) {
      http.post('v1/myself/contents/access', { contentId })
    },
    attemptToggleProgress (_, contentId) {
      return http.post(`v1/contents/${contentId}/toogle-in-progress`)
    },
    attemptToggleConclusion (_, contentId) {
      return http.post(`v1/myself/contents/${contentId}/toogle-conclusion`)
    },
    attemptSetContentInteraction (_, { contentId, interaction }) {
      return http.post(`v1/contents/${contentId}/interaction`, { interaction })
    },
    attemptGetContentsByList (_, listId) {
      return http.get(`v1/contents/lists/${listId}`)
    },
    attemptGetContentById (_, contentId) {
      return http.get(`v1/contents/${contentId}`)
    },
    attemptRemoveContent (_, contentId) {
      return http.delete(`v1/contents/${contentId}`).then(({ data }) => data)
    },
    attemptAssociateContentWithList (_, { contentId, lists }) {
      return http.post(`v1/contents/${contentId}/lists`, lists)
    },
    attemptRemoveContentFromList (_, { contentId, listId }) {
      return http.delete(`v1/lists/${listId}/contents/${contentId}`)
    },
    attemptExtractMetaInfos (_, url) {
      return http.get(`v1/extract-meta?url=${encodeURIComponent(url)}`)
    },
    attemptGetCourseSuggestions (_, { aggregator }) {
      return hireHttp.get(`v1/courses/suggestions${aggregator.workSpaceType === 'aggregator' ? `?aggregator=${aggregator.CompanyDomain}` : ''}`).then(({ data }) => {
        return data.course
      })
    },
    attemptGetCourseDetailsById (_, { courseId }) {
      return hireHttp.get(`v1/courses/details/${courseId}`).then(({ data }) => {
        return data.course
      })
    },
    attemptGetCourseRelatedData (_, { courseId, aggregator }) {
      return hireHttp.get(`v1/courses/related/${courseId}${aggregator.workSpaceType === 'aggregator' ? `?aggregator=${aggregator.CompanyDomain}` : ''}`).then(({ data }) => {
        return data
      })
    },
    attemptGetFavoriteCourses (_, { aggregator }) {
      return hireHttp.get(`v1/courses/saved${aggregator.workSpaceType === 'aggregator' ? `?aggregator=${aggregator.CompanyDomain}` : ''}`).then(({ data }) => {
        return data.course
      })
    },
    attemptGetEnrolledCourses (_) {
      return hireHttp.get('v1/courses/enroll').then(({ data }) => {
        return data
      })
    },
    attemptEnrollInCourse (_, courseId) {
      return hireHttp.post('v1/courses/enroll', { course_id: courseId }).then(({ data }) => {
        return data
      })
    },
    attemptSaveFavoriteCourse (_, courseId) {
      return hireHttp.post('v1/courses/saved', courseId)
    },
    attemptRemoveFavoriteCourse (_, courseId) {
      return hireHttp.delete('v1/courses/saved', { data: courseId })
    },
    attemptToggleFavoriteContent (_, contentId) {
      return http.post(`v1/myself/saved-contents/${contentId}`)
    },
    attemptGetPositionDetailsById (_, { positionId, aggregator, lang }) {
      let queryString = '?'
      if (aggregator && aggregator.workSpaceType === 'aggregator') {
        queryString += `aggregator=${aggregator.CompanyDomain}`
      }
      if (lang) {
        if (queryString.length > 1) queryString += '&'
        queryString += `lang=${lang}`
      }
      return hireHttp.get(`v1/positions/${positionId}${queryString}`).then(({ data }) => {
        return data
      })
    },
    attemptGetPositionRelatedData (_, { positionId, aggregator }) {
      return hireHttp.get(`v1/positions/related/${positionId}${!!aggregator && aggregator.workSpaceType === 'aggregator' ? `?aggregator=${aggregator.CompanyDomain}` : ''}`).then(({ data }) => {
        return data
      })
    },
    attemptSaveFavoritePosition (_, positionId) {
      return hireHttp.put('v1/favorite/' + positionId)
    },
    attemptRemoveFavoritePosition (_, positionId) {
      return hireHttp.delete(`v1/favorite/${positionId}`)
    },
    attemptGetFeedPlooralNews () {
      return hireHttp.get('v1/-/rss')
    }
  }
}
